import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  ContentWrapper,
  DescriptionWrapper,
  Title,
  DescriptionTitle,
  Number,
  Description,
  DescriptionItem,
  DescriptionText,
  ModalLink,
  HorizontalDivider,
  BackgroundIcons,
  Icon,
  BlackBorderLeft,
  BlackBorderRight,
  ArrowLeft,
  ArrowRight,
} from "./style";
import {
  transitionTimeoutMS,
  nextPageKeys,
  previousPageKeys,
  icons,
  DataAttributes,
} from "../utils";
import ViewFromOffice from "../ViewFromOffice/Desktop";
import Modal from "../../../../../common/Modal";
import Breakpoint from "../../../../../common/Breakpoint";
import { PageChange } from "../../../../../common/Scroll";
import { numberToFixedLength } from "../../../../../../utils/common";
import { fixConjunctions } from "../../../../../../utils/dropTheWord";
import { PageTitle } from "../../../../../common/PageTitle";

interface WhatWeOfferProps {
  pageChange: PageChange;
  setOpen: (value: boolean) => void;
  goToPage?: (newPage: number) => void;
  blockScroll?: (block: boolean) => void;
}

const WhatWeOffer: React.FC<WhatWeOfferProps> = ({
  pageChange,
  setOpen,
  goToPage,
  blockScroll,
}) => {
  const [page, setPage] = useState<number>(0);
  const [lastAbsoluteScrollValue, setLastAbsoluteScrollValue] = useState<
    number
  >(0);
  const [lastScroll, setLastScroll] = useState<number>(0);
  const [transitionOpen, setTransitionOpen] = useState<boolean>(false);
  const [openModal, setModalOpen] = useState<boolean>(false);

  const { t } = useTranslation("career");
  const data = ((t(`whatWeOffer_data`) as unknown) as DataAttributes[]).map(
    item => {
      const optionalLink =
        item.optionalLink && item.name === "location" ? (
          <ModalLink
            onClick={() => {
              setTransitionOpen(true);
              setOpen(true);
              setModalOpen(true);
            }}
          >
            {fixConjunctions(item.optionalLink)}
          </ModalLink>
        ) : undefined;

      return {
        ...item,
        icon: icons[item.name],
        optionalLink,
      };
    }
  );
  const currentData = data[page];
  const nOfPages = data && data.length - 1;

  const FIRST_PAGE = 0;
  const LAST_PAGE = data.length - 1;

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const timeNow = Date.now();
      const timeFromLastScroll = timeNow - lastScroll;
      const transitionTimeout = 2 * transitionTimeoutMS;

      if (!!pageChange || timeFromLastScroll < transitionTimeout) {
        return;
      }

      const keyDown = e.code;
      const scroll = document.body.scrollTop;
      const scrollRange =
        document.body.scrollHeight - document.body.clientHeight;

      if (
        page !== nOfPages &&
        nextPageKeys.includes(keyDown) &&
        ((scrollRange !== 0 && scroll >= scrollRange) || scrollRange === 0)
      ) {
        page === 0 && blockScroll && blockScroll(true);
        setPage(page + 1);
        setLastScroll(timeNow);
      } else if (
        page > 0 &&
        previousPageKeys.includes(keyDown) &&
        ((scrollRange !== 0 && scroll <= 0) || scrollRange === 0)
      ) {
        page === nOfPages && blockScroll && blockScroll(true);
        setPage(page - 1);
        setLastScroll(timeNow);
      } else if (page === 0 || page === nOfPages) {
        blockScroll && blockScroll(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [page, lastScroll, lastAbsoluteScrollValue]);

  const handleClick = (index: number) => {
    if (index !== page) {
      setPage(index);
    }
  };

  const handleCloseModal = () => {
    setTransitionOpen(false);
    setTimeout(() => {
      setOpen(false);
      setModalOpen(false);
    }, 600);
  };

  return (
    <>
      <PageTitle subtitle={fixConjunctions(t(`whatWeOffer_title`))} />
      <PageWrapper animation={pageChange}>
        <ContentWrapper>
          <div>
            <Title whiteSpace="pre">
              {fixConjunctions(t(`whatWeOffer_title`))}
            </Title>
            <HorizontalDivider />
          </div>

          <Breakpoint device={["tablet", "tabletLandscape"]}>
            <BackgroundIcons currentIndex={page}>
              {data.map((icon, index) => (
                <Icon
                  key={index}
                  active={page === index ? 1 : 0}
                  onClick={() => handleClick(index)}
                >
                  {icon.icon}
                </Icon>
              ))}
            </BackgroundIcons>
            <ArrowLeft
              onClick={() => (page > FIRST_PAGE ? handleClick(page - 1) : null)}
              currentIndex={page}
            />
            <ArrowRight
              onClick={() => (page < LAST_PAGE ? handleClick(page + 1) : null)}
              currentIndex={page}
            />
            <BlackBorderLeft />
            <BlackBorderRight />
          </Breakpoint>

          <DescriptionWrapper>
            <DescriptionTitle>
              {fixConjunctions(currentData.title)}
            </DescriptionTitle>
            <Description>
              {Object.values(currentData.description).map(el => (
                <DescriptionItem>
                  {"\u2022"} <DescriptionText>{el}</DescriptionText>
                </DescriptionItem>
              ))}
            </Description>
            {currentData.optionalLink || undefined}
          </DescriptionWrapper>
        </ContentWrapper>

        <Breakpoint device="computer">
          <BackgroundIcons currentIndex={page}>
            {data.map((icon, index) => (
              <Icon
                key={index}
                active={page === index ? 1 : 0}
                onClick={() => handleClick(index)}
              >
                {icon.icon}
              </Icon>
            ))}
          </BackgroundIcons>
          <ArrowLeft
            onClick={() => (page > FIRST_PAGE ? handleClick(page - 1) : null)}
            currentIndex={page}
          />
          <ArrowRight
            onClick={() => (page < LAST_PAGE ? handleClick(page + 1) : null)}
            currentIndex={page}
          />
          <BlackBorderLeft />
          <BlackBorderRight />
        </Breakpoint>

        <Modal
          open={openModal}
          handleClose={handleCloseModal}
          style={{ background: "none" }}
        >
          <ViewFromOffice
            transitionOpen={transitionOpen}
            handleClose={handleCloseModal}
          />
        </Modal>
      </PageWrapper>
    </>
  );
};

export default WhatWeOffer;
