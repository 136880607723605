import styled, { css } from "styled-components";

import { device } from "../../../../../../utils/breakpoints";
import { fadeIn, fadeOut } from "../../../../../../utils/animations";
import ArrowLeftImg from "-!svg-react-loader!../../../../../../assets/images/arrowLeftWhite.svg";
import ArrowRightImg from "-!svg-react-loader!../../../../../../assets/images/arrowRightWhite.svg";
import {
  H2,
  H2_REGULAR,
  H3,
  H4,
  H6,
  TEXT_BUTTON_2,
} from "../../../../../../assets/styles/typography";

interface PageProps {
  animation?: string;
}

export const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  background: #151515;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  padding: 88px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;

  @media ${device.computer} {
    padding: 80px 0 80px 120px;
    width: 35%;
    pointer-events: none;
  }

  @media ${device.desktop} {
    padding: 128px 0 120px 120px;
    width: 36%;
  } ;
`;

export const DescriptionWrapper = styled.div`
  @media ${device.tablet} {
    position: absolute;
    top: 522px;
  }
`;

interface TitleProps {
  whiteSpace?: string;
}

export const Title = styled.div`
  width: 100%;
  margin: 0;
  font-size: 36px;
  line-height: 46px;
  font-weight: 700;
  color: #ffffff;
  white-space: ${(props: TitleProps) => props.whiteSpace || "pre-line"};

  @media ${device.computer} {
    ${H3}
  }

  @media ${device.desktop} {
    ${H2}
  } ;
`;

export const DescriptionTitle = styled.div`
  ${H4}
  color: #FFFFFF;
  width: 100%;
  white-space: ${(props: TitleProps) => props.whiteSpace || "pre-line"};

  @media ${device.desktop} {
    ${H2}
  }
`;

export const Number = styled.div`
  ${H2_REGULAR}
  width: 100%;
  margin: 0;
  color: #ffffff;
`;

export const Description = styled.div`
  ${TEXT_BUTTON_2}
  width: 90%;
  margin: 20px 0 0 0;
  color: #ffffff;
  white-space: pre-line;

  @media ${device.tablet}, ${device.tabletLandscape} {
    width: 70%;
  }

  @media ${device.computer} {
    width: 100%;
  } ;
`;

export const DescriptionItem = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;

  @media ${device.computer} {
    margin-top: 8px;
  }

  @media ${device.desktop} {
    margin-top: 15px;
  } ;
`;

export const DescriptionText = styled.div`
  padding-left: 5px;
`;

export const ModalLink = styled.div`
  ${H6}
  right: 118px;
  bottom: 48px;
  text-transform: uppercase;
  color: #013cff;
  white-space: pre-line;
  pointer-events: all;
  cursor: pointer;

  @media ${device.tablet} {
    margin-top: 24px;
  }

  @media ${device.computer}, ${device.desktop} {
    margin-top: 40px;
  } ;
`;

export const HorizontalDivider = styled.hr`
  margin: 32px 0 48px 0;
  display: block;
  width: 112px;
  height: 6px;
  background: #ffffff;

  @media ${device.tabletLandscape} {
    margin-bottom: 0;
  }

  @media ${device.computer} {
    width: 96px;
    height: 8px;
    margin: 32px 0 0 0;
  } ;
`;

interface IconsProps {
  currentIndex: number;
}

export const BackgroundIcons = styled.div`
  position: relative;
  margin: 0;
  width: 100%;
  white-space: nowrap;
  transform: translate3d(
      ${(props: IconsProps) => (props.currentIndex - 1) * -27}%,
      0,
      0
    )
    translateX(-12px);
  transition: 0.3s ease-in-out;

  @media ${device.tablet} {
    top: 3%;
    height: 97%;
  }

  @media ${device.tabletLandscape} {
    transform: translate3d(
        ${(props: IconsProps) => (props.currentIndex - 1) * -26}%,
        0,
        0
      )
      translateX(12px);
    top: 2%;
    height: 98%;
  }

  @media (min-width: 1024px) and (orientation: portrait) {
    transform: translate3d(
        ${(props: IconsProps) => (props.currentIndex - 1) * -22}%,
        0,
        0
      )
      translateX(64px);
  }

  @media (min-width: 1256px) and (orientation: landscape) {
    transform: translate3d(
        ${(props: IconsProps) => (props.currentIndex - 1) * -20}%,
        0,
        0
      )
      translateX(112px);
  }

  @media ${device.computer} {
    position: absolute;
    top: 16%;
    left: 48%;
    width: 50%;
    height: 84%;
    transform: translate3d(
      ${(props: IconsProps) => props.currentIndex * -28}%,
      0,
      0
    );
  }

  @media ${device.desktop} {
    top: 29%;
    left: 50%;
    width: 60%;
    height: 71%;
  } ;
`;

interface IconProps {
  active?: number;
}

export const Icon = styled.div`
  width: ${(props: IconProps) => (props.active ? "36%" : "12%")};
  display: inline-block;
  margin: auto 43px;
  transform: ${(props: IconProps) =>
    props.active ? "scale(1.1)" : "scale(0.8)"};
  transition: 0.3s ease-in-out;
  cursor: ${(props: IconProps) => (props.active ? "arrow" : "pointer")};

  svg {
    width: 100%;
    height: 25vh;
    filter: ${(props: IconProps) => (props.active ? "none" : "blur(3px)")};
  }

  @media ${device.tabletLandscape} {
    width: ${(props: IconProps) => (props.active ? "36%" : "16%")};
    transform: ${(props: IconProps) =>
      props.active ? "scale(1)" : "scale(0.6)"};
  }

  @media (min-width: 1024px) and (orientation: portrait),
    (min-width: 1256px) and (orientation: landscape) {
    width: ${(props: IconProps) => (props.active ? "40%" : "12%")};
  }

  @media ${device.computer} {
    width: ${(props: IconProps) => (props.active ? "38%" : "12%")};
    margin: auto 8%;
    transform: none;

    svg {
      height: 60vh;
    }
  }

  @media ${device.desktop} {
    width: ${(props: IconProps) => (props.active ? "40%" : "12%")};
    transform: ${(props: IconProps) =>
      props.active ? "scale(0.9)" : "scale(0.5)"};
    margin: ${(props: IconProps) => (props.active ? "auto 2%" : "auto 8%")};

    svg {
      height: 33vh;
    }
  }
`;

export const BlackBorderLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 100%;
  background: #151515;
  z-index: 1;

  @media ${device.computer} {
    width: 31%;
  }

  @media ${device.desktop} {
    width: 36%;
  }
`;

export const BlackBorderRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 100%;
  background: #151515;
  z-index: 1;

  @media ${device.computer} {
    width: 116px;
  }

  @media ${device.desktop} {
    width: 9%;
  } ;
`;

export const ArrowLeft = styled(ArrowLeftImg)`
  position: absolute;
  cursor: pointer;
  padding: 24px;
  border-radius: 8px;
  display: ${(props: IconsProps) =>
    props.currentIndex === 0 ? "none" : "block"};

  @media ${device.tablet} {
    width: 80px;
    height: 80px;
    bottom: 88px;
    left: 112px;
    padding: 0;
    transition: 0.5s;

    &:hover {
      transition: 0.5s;
      opacity: 0.24;
    }
  }

  @media ${device.tabletLandscape} {
    width: 120px;
    height: 120px;
    bottom: 88px;
    right: 220px;
    transition: 0.5s;

    &:hover {
      transition: 0.5s;
      opacity: 0.24;
    }
  }

  @media ${device.computer} {
    right: 550px;
    bottom: 60px;
    width: 100px;
    height: 90px;
    transition: 0.5s;

    &:hover {
      transition: 0.5s;
      opacity: 0.24;
    }
  }

  @media ${device.desktop} {
    right: 1060px;
    bottom: 120px;
    width: 120px;
    height: 103.91px;
    background: rgba(255, 255, 255, 0.06);

    &:hover {
      transition: 0.5s;
      background: rgba(255, 255, 255, 0.16);
      opacity: 1;
    }

    &:active {
      background: rgba(255, 255, 255, 0.24);
    }
  } ;
`;

export const ArrowRight = styled(ArrowRightImg)`
  position: absolute;
  cursor: pointer;
  padding: 24px;
  border-radius: 8px;
  display: ${(props: IconsProps) =>
    props.currentIndex === 7 ? "none" : "block"};

  @media ${device.tablet} {
    width: 80px;
    height: 80px;
    right: 110px;
    bottom: 88px;
    padding: 0;
    transition: 0.5s;

    &:hover {
      transition: 0.5s;
      opacity: 0.24;
    }
  }

  @media ${device.tabletLandscape} {
    width: 120px;
    height: 120px;
    bottom: 88px;
    right: 90px;
    transition: 0.5s;

    &:hover {
      transition: 0.5s;
      opacity: 0.24;
    }
  }

  @media ${device.computer} {
    width: 100px;
    height: 90px;
    right: 410px;
    bottom: 60px;
    transition: 0.5s;

    &:hover {
      transition: 0.5s;
      opacity: 0.24;
    }
  }

  @media ${device.desktop} {
    width: 120px;
    height: 109.81px;
    right: 230px;
    bottom: 120px;
    background: rgba(255, 255, 255, 0.06);

    &:hover {
      transition: 0.5s;
      background: rgba(255, 255, 255, 0.16);
      opacity: 1;
    }

    &:active {
      background: rgba(255, 255, 255, 0.24);
    }
  } ;
`;
