import styled, { css } from "styled-components";

import { device } from "../../../../../../../utils/breakpoints";
import { fadeIn, fadeOut } from "../../../../../../../utils/animations";
import {
  H3,
  H4,
  TEXT_BUTTON_2,
} from "../../../../../../../assets/styles/typography";

interface WrapperProps {
  open?: number;
}

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  color: #ffffff;
  background: #151515;
  z-index: 2;
  animation: ${(props: WrapperProps) =>
    props.open
      ? css`
          ${fadeIn} 300ms linear
        `
      : css`
          ${fadeOut} 300ms linear
        `};
  animation-fill-mode: forwards;

  svg {
    fill: #ffffff;
  }
`;

export const MainWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const Side = styled.div`
  position: absolute;
  width: 35%;
  height: 58%;
  top: 0;
  right: 0;
  display: grid;
  grid-template-columns: 16% 8% 8% 6% 12% 8% 18% 24%;
  grid-template-rows: 10% 12% 20% 10% 10% 16% 10% 12%;
  z-index: 1;

  @media ${device.tablet}, ${device.tabletLandscape} {
    width: auto;
    height: auto;
    padding: 60px 64px;
    top: auto;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #013cff;
  }

  @media ${device.computer} {
    height: 100vh;
    width: calc(450px + 120px);
  }

  @media ${device.desktop} {
    width: calc(539px + 120px);
  } ;
`;

export const CloseModalWrapper = styled.div`
  grid-column: 4 / span 5;
  grid-row: 2;
  width: 3rem;
  height: 3rem;
  margin: 0 auto;
  font-size: 3rem;
  user-select: none;
  transition: 0.3s;

  svg > * {
    fill: #151515;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.24;
    transition: 0.3s;
  }

  @media ${device.computer} {
    position: absolute;
    top: 60px;
    right: 120px;
    grid-column: none;
    grid-row: none;
  }

  @media ${device.desktop} {
    top: 100px;
  } ;
`;

export const Title = styled.h2`
  margin: auto auto 0 auto;
  grid-column: 4 / span 5;
  grid-row: 3;
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;

  @media ${device.tablet}, ${device.tabletLandscape} {
    ${H4}
    margin: 0;
    margin-bottom: 24px;
  }

  @media ${device.computer} {
    ${H4}
    position: absolute;
    bottom: 208px;
    left: 64px;
    grid-column: none;
    grid-row: none;
  }

  @media ${device.desktop} {
    ${H3}
  } ;
`;

export const Description = styled.div`
  ${TEXT_BUTTON_2}
  grid-column: 5 / span 4;
  grid-row: 5 / span 3;
  margin: 0 auto;
  white-space: pre-line;
  color: #ffffff;

  @media ${device.tablet}, ${device.tabletLandscape} {
    margin: 0;
  }

  @media ${device.computer} {
    position: absolute;
    bottom: 64px;
    left: 64px;
    grid-column: none;
    grid-row: none;
  } ;
`;

export const BackgroundWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 16% 8% 8% 6% 12% 8% 18% 24%;
  grid-template-rows: 10% 12% 20% 10% 10% 16% 10% 12%;
  z-index: -1;
`;

export const BlueRectangle = styled.div`
  position: absolute;
  bottom: 0;
  right: 120px;
  width: 450px;
  height: 314px;
  margin-left: auto;
  background-color: #013cff;

  @media ${device.desktop} {
    width: 539px;
    height: 324px;
  }
`;

export const GoBackArrowWrapper = styled.div`
  position: absolute;
  top: 40px;
  left: 40px;
  z-index: 1;

  svg > * {
    fill: #151515 !important;
  }
`;
