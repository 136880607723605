import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";

import { 
  Wrapper, 
  MainWrapper,
  Side,
  CloseModalWrapper,
  Title, 
  Description,
  BackgroundWrapper,
  BlueRectangle,
  GoBackArrowWrapper
} from "./style";
import Breakpoint from "../../../../../../common/Breakpoint";
import BackgroundSlider from "../../../../../../common/ImageSlider";
import { fixConjunctions } from "../../../../../../../utils/dropTheWord";
import ArrowLeft from "-!svg-react-loader!../../../../../../../assets/images/arrowLeft.svg";
import CloseIcon from "-!svg-react-loader!../../../../../../../assets/images/closeIcon.svg";

interface ViewFromOfficeProps {
  transitionOpen: boolean;
  handleClose: () => void;
};

const ViewFromOffice: React.FC<ViewFromOfficeProps> = ({ 
  transitionOpen, 
  handleClose 
}) => {
  const { t } = useTranslation("career");
  const common = useTranslation("common");
  const data = useStaticQuery(backgroundQuery);

  return (
    <Wrapper open={transitionOpen ? 1 : 0}>
      <Breakpoint device={["tablet", "tabletLandscape"]}>
        <GoBackArrowWrapper onClick={handleClose}>
          <ArrowLeft />
        </GoBackArrowWrapper>
      </Breakpoint>

      <MainWrapper>
        <BackgroundSlider images={data.allFile.nodes} />
      </MainWrapper>
      <Side>
        <Breakpoint device="computer">
          <CloseModalWrapper>
            <CloseIcon onClick={handleClose} />
          </CloseModalWrapper>
        </Breakpoint>

        <Title>{fixConjunctions(t(`viewFromOffice_title`))}</Title>
        <Description>
          {common.t(`OKE`)}
          <br />
          {common.t(`location`)}
        </Description>

        <Breakpoint device="computer">
          <BackgroundWrapper>
            <BlueRectangle />
          </BackgroundWrapper>
        </Breakpoint>
      </Side>
    </Wrapper>
  );
};

const backgroundQuery = graphql`
{
  allFile(filter: { relativeDirectory: { eq: "view" } }) {
    nodes {
      childImageSharp {
        fluid(maxHeight: 1080, quality: 100) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
}
`;

export default ViewFromOffice;